import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formLogin!: FormGroup;

  hide = true;
  isForgotPassword = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.formLogin = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.min(3)]),
    });
  }

  onSubmit(value: any) {
    this.authService
      .getToken(value)
      .then(response => {
        const dataResponse = response.data;
        if (dataResponse.firstAccessVerification === 'ACTIVE') {
          const accessToken = dataResponse.token;
          document.cookie = `access=${accessToken}; max-age=604800; path=/; Secure; SameSite=Strict`;

          const permissions = response.data.group[0].permissions
          sessionStorage.setItem('permissions', JSON.stringify(permissions));

          this.router.navigateByUrl('/pages/dashboard');
        } else if (
          ['PENDING', 'PASSWORD_CHANGE'].indexOf(
            dataResponse.firstAccessVerification
          ) !== -1
        ) {
          this.router.navigate([
            '/auth/update-password',
            dataResponse.username,
          ]);
        }
      })
      .catch(error => {
        this.toastrService.error(error.message, 'Erro');
      });
  }

  forgotPassword() {
    this.isForgotPassword = true;
  }

  submitRecoverPassword(value: any) {
    this.authService.recoverPassword(value).then(() => {
      this.toastrService.info('Senha enviada para recuperação', 'Atenção');
      this.isForgotPassword = false;
    });
  }
}
