<mat-toolbar color="grey">
  <mat-toolbar-row>
    <button *ngIf="canGoBack" mat-icon-button (click)="changeView()">
      <mat-icon>reply</mat-icon>
    </button>
    <button *ngIf="isViewMode" mat-icon-button (click)="add()">
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="saved()"
      *ngIf="isEditMode"
      [disabled]="!canSave">
      <mat-icon>check</mat-icon>
    </button>
    <button *ngIf="canDelete" mat-icon-button color="warn" (click)="delete()">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
