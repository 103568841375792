import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/service/auth.service';

export const authGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const token = authService.getCookie('access');

  if (token) {
    return true;
  } else {
    await router.navigate(['/auth']);
    return false;
  }
};
