<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"
      ><img
        alt="EzAgro Home"
        src="assets/icons/ez_agro_logo_horizontal.svg"
        [attr.height]="'60px'"
    /></a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item"> </nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user
        nbContextMenuTag="context-user"
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name"
        [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
