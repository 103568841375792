import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpNoAuthService } from './http-no-auth.service';

const API_LOGIN = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpNoAuthService: HttpNoAuthService) {}

  getToken(data: any) {
    return this.httpNoAuthService.post(API_LOGIN + '/authenticate', data);
  }

  updatePassword(data: any) {
    return this.httpNoAuthService.post(API_LOGIN + '/update-password', data);
  }

  recoverPassword(data: any) {
    return this.httpNoAuthService.post(API_LOGIN + '/recover-password', data);
  }

  getCookie(name: string) {
    const matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  hasPermission(permissionsToCheck: string[]): boolean {
    const permissions = sessionStorage.getItem('permissions')
      ? JSON.parse(sessionStorage.getItem('permissions')!)
      : [];
    const permissionsNames = permissions.map(
      (permission: { id: number; name: string; description: string }) =>
        permission.name
    );
  
    return permissionsToCheck.every(permission => permissionsNames.includes(permission));
  }

  getUserName() {
    const dataString = localStorage.getItem('access');
    let userName = '';

    if (dataString != null) {
      const data = JSON.parse(dataString);
      if (data.employee?.id) {
        userName = data.employee.name;
      } else if (data.outsource?.id) {
        userName = data.outsource.name;
      } else {
        userName = data.client.name;
      }
    }

    return userName;
  }

  getAccessGroup() {
    const dataString = localStorage.getItem('access');
    if (dataString != null) {
      const data = JSON.parse(dataString);
      return data.group;
    }

    return null;
  }
}
