import { Injectable } from '@angular/core';
import axios, { AxiosInstance } from 'axios';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import loadingService from './loading.service';
import { AuthService } from './auth.service';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private axiosInstance: AxiosInstance;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    this.axiosInstance = axios.create({
      baseURL: API_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    // Request interceptor
    this.axiosInstance.interceptors.request.use(
      config => {
        loadingService.show();

        const accessToken = this.authService.getCookie('access');

        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
      },
      error => {
        loadingService.hide();
        return Promise.reject(error);
      }
    );

    // Response interceptor
    this.axiosInstance.interceptors.response.use(
      response => {
        loadingService.hide();
        return response;
      },
      error => {
        loadingService.hide();
        if (error.response && error.response.status === 401) {
          this.router.navigate(['/']);
        } else {
          let message = error.message;

          if (error.response?.data) {
            message = error.response.data.message;
          }

          console.error(message);
        }

        return Promise.reject(error);
      }
    );
  }

  get(url: string, config?: any) {
    return this.axiosInstance.get(url, config);
  }

  post(url: string, data: any, config?: any) {
    return this.axiosInstance.post(url, data, config);
  }

  put(url: string, data: any, config?: any) {
    return this.axiosInstance.put(url, data, config);
  }

  patch(url: string, data: any, config?: any) {
    return this.axiosInstance.patch(url, data, config);
  }

  delete(url: string, config?: any) {
    return this.axiosInstance.delete(url, config);
  }
}
