import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../service/auth.service';
import loadingService from '../../service/loading.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly toastrService: ToastrService,
    private readonly authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    loadingService.show();

    const accessToken = this.authService.getCookie('access');
    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          loadingService.hide();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        loadingService.hide();
        if (error.status === 401) {
          this.router.navigate(['/']);
        } else {
          this.toastrService.error(error?.error?.message ?? error.message);
        }
        return throwError(() => new Error('Erro de conexão com o servidor'));
      })
    );
  }
}
