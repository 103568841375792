import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../service/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit {
  formNewPassword!: FormGroup;
  username: string;
  hide = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.username = params['username'];
    });
    this.createForm();
  }

  createForm() {
    this.formNewPassword = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.min(3)]),
    });
  }

  onSubmit(value: {password: string}) {
    const data: any = {
      username: this.username,
      password: value.password,
    };
    this.authService.updatePassword(data).then(() => {
      this.toastrService.show('Senha atualizada com sucesso!', 'Confirmação');
      this.router.navigateByUrl('/');
    });
  }
}
