import { Injectable } from '@angular/core';
import { HttpService } from './http-service';
import { ObservableHttpService } from './observable-http.service';
import { Observable } from 'rxjs';
import { IUserPost } from 'app/shared/interfaces/user.interface';
import { IResponse } from 'app/shared/interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly entity_url = 'user';

  constructor(private readonly observableHttpService: ObservableHttpService, private httpService: HttpService) {}

  registerUser(data: any) {
    return this.httpService.post('/user/register', data);
  }

  userLock(data: any) {
    return this.httpService.post('/user/user-lock', data);
  }

  getUsers(params?: any) {
    return this.httpService.get('/user', { params: params });
  }

  getAccessGroup() {
    return this.httpService.get('/user/access-group');
  }

  deleteUser(params: any) {
    return this.httpService.delete('/user/' + params.id);
  }

  updateUser(user: IUserPost): Observable<IResponse<IUserPost>> {
    return this.observableHttpService.patch(`${this.entity_url}/${user.id}`, user);
  }
}
