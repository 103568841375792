import { Injectable } from '@angular/core';
import { ICreateRegister, ILogin, ILoginResponse } from 'app/shared/interfaces/user.interface';
import { Observable } from 'rxjs';
import { ObservableHttpService } from './observable-http.service';
import { IResponse } from 'app/shared/interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly entity_url = 'authentication';

  constructor(private readonly observableHttpService: ObservableHttpService) {}

  getToken(login: ILogin): Observable<IResponse<ILoginResponse>> {
    return this.observableHttpService.postLogin<ILogin, IResponse<ILoginResponse>>(this.entity_url+'/login', login);
  }

  forgotPassword(data: ILogin): Observable<IResponse<ILogin>> {
    return this.observableHttpService.post(this.entity_url+'/forgot-password', data);
  }

  registerUser(data: ICreateRegister): Observable<IResponse<ICreateRegister>> {
    return this.observableHttpService.post<ICreateRegister>(this.entity_url+'/register', data);
  }

  getCookie(name: string) {
    const matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
      )
    );
    return matches ? matches[1] : undefined;
  }

  hasPermission(permissionsToCheck: string[]): boolean {
    const permissions = sessionStorage.getItem('permissions')
      ? JSON.parse(sessionStorage.getItem('permissions')!)
      : [];
    const permissionsNames = permissions.map(
      (permission: { id: number; name: string; slug: string }) =>
        permission.slug
    );
  
    return permissionsToCheck.every(permission => permissionsNames.includes(permission));
  }

  getUserName() {
    const dataString = localStorage.getItem('access');
    let userName = '';

    if (dataString != null) {
      const data = JSON.parse(dataString);
      if (data.employee?.id) {
        userName = data.employee.name;
      } else if (data.outsource?.id) {
        userName = data.outsource.name;
      } else {
        userName = data.client.name;
      }
    }

    return userName;
  }
}
