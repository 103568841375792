<div class="wrapper fadeInDown">
  <div id="formContent">
    <div class="m-5">
      <img
        alt="EzAgro Logotipo"
        src="../../../../assets/icons/ez_agro_logo_vertical.svg"
        [attr.width]="'200px'" />
    </div>

    <form [formGroup]="formNewPassword">
      <div class="col-md-12 col-xs-12 pb-2">
        <div class="m-5 justify-content-center">
          <div class="col-md-12">
            <mat-form-field id="password" class="full-width-field">
              <mat-label>Nova Senha</mat-label>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
                required />
            </mat-form-field>
          </div>
        </div>
        <button
          class="btn btn-outline-success"
          (click)="onSubmit(formNewPassword.value)">
          Alterar
        </button>
      </div>
    </form>
  </div>
</div>
