/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ngx-navigation-toolbar',
  templateUrl: './navigation-toolbar.component.html',
  styleUrls: ['./navigation-toolbar.component.scss'],
})
export class NavigationToolbarComponent implements OnChanges, AfterViewInit {
  @Input() isViewMode = true;
  @Input() isEditMode = false;
  @Input() isUpdateMode = false;
  @Input() formGroup: FormGroup;
  @Input() canGoBack = true;
  @Input() canDelete = true;
  @Input() canSave = true;
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaved: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeView: EventEmitter<any> = new EventEmitter<any>();

  ngAfterViewInit(): void {
    if (this.formGroup) {
      this.formGroup.valueChanges.subscribe(() => {
        if (!this.isViewMode && this.formGroup.dirty) {
          this.isEditMode = true;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isViewMode']) {
      this.isViewMode = changes['isViewMode'].currentValue;
    }

    if (changes['isEditMode']) {
      this.isEditMode = changes['isEditMode'].currentValue;
    }

    if (changes['isUpdateMode']) {
      this.isUpdateMode = changes['isUpdateMode'].currentValue;
    }
  }

  add() {
    if (this.formGroup != undefined && this.formGroup) {
      this.formGroup.reset();
    }

    this.onAdd.emit();
    this.isViewMode = false;
    this.isEditMode = false;
    this.isUpdateMode = false;
  }

  saved() {
    this.onSaved.emit();
    this.isViewMode = true;
    this.isEditMode = false;
    this.isUpdateMode = false;
    if (this.formGroup) {
      this.formGroup.reset();
    }
  }

  changeView() {
    this.isViewMode = !this.isViewMode;
    this.onChangeView.emit();
    this.isUpdateMode = false;
    this.isEditMode = false;
  }

  delete() {
    this.onDelete.emit();
  }
}
