import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../service/auth.service';
import { ILogin, ILoginResponse } from 'app/shared/interfaces/user.interface';
import { IResponse } from 'app/shared/interfaces/response.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  formLogin!: FormGroup;

  hide = true;
  isForgotPassword = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.formLogin = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.min(3)]),
    });
  }

  onSubmit(value: ILogin) {
    this.subscriptions.push(
      this.authService.getToken(value).subscribe({
        next: (response: IResponse<ILoginResponse>) => {
          const accessToken = response.data?.token;
    
          document.cookie = `access=${accessToken}; max-age=604800; path=/; Secure; SameSite=Strict`;
    
          const permissions = response.data?.user.accessGroups[0].permissions
          sessionStorage.setItem('permissions', JSON.stringify(permissions));
    
          const isResetPassword = response.data?.user.isResetPassword;
  
          if (isResetPassword) {
            this.router.navigate(['/auth/update-password', response.data?.user.id,])
          } else {
            this.router.navigateByUrl('/pages/dashboard');
          }
        },
        error: (error: any) => {
          console.error('Erro ao fazer login:', error);
          this.toastrService.error(
            error?.error?.message ?? 'Erro ao fazer login. Tente novamente.',
            'Erro'
          );
        },
      })
    )
  }

  forgotPassword() {
    this.isForgotPassword = true;
  }

  submitRecoverPassword(value: any) {
    this.subscriptions.push(
      this.authService.forgotPassword(value).subscribe({
        next: () => {
          this.toastrService.info('Senha enviada para recuperação', 'Atenção');
          this.isForgotPassword = false;
        },
        error: error => {
          console.error(error);
          this.toastrService.error('Falha ao enviar senha para recuperação', 'Erro')
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
