<div *ngIf="title" mat-dialog-title>{{ title }}</div>
<mat-dialog-content class="confirm-dialog-size">
  <span class="text-black">{{ data.message }}</span>
</mat-dialog-content>
<mat-dialog-actions class="ps-4">
  <button class="btn me-2 btn-outline-danger" (click)="onConfirm()">
    Confirmar
  </button>
  <button class="btn btn-outline-primary" (click)="onCancel()">Cancelar</button>
</mat-dialog-actions>
