import { Component } from '@angular/core';
import loadingService from '../../service/loading.service';

@Component({
  selector: 'ngx-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  loadingService = loadingService;
}
