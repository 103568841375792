<div class="wrapper fadeInDown">
  <div id="formContent">
    <div class="m-5">
      <img
        alt="EzAgro Logotipo"
        src="../../../../assets/icons/ez_agro_logo_vertical.svg"
        [attr.width]="'200px'" />
    </div>

    <form [formGroup]="formLogin">
      <div class="col-md-12 col-xs-12 pb-2">
        <div class="m-5 justify-content-center">
          <div class="col-md-12">
            <mat-form-field id="username" class="full-width-field">
              <mat-label>CPF</mat-label>
              <input
                matInput
                formControlName="username"
                [mask]="'000.000.000-00'"
                required />
            </mat-form-field>
          </div>
          <div class="col-md-12" *ngIf="!isForgotPassword">
            <mat-form-field id="password" class="full-width-field">
              <mat-label>Senha</mat-label>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
                required />
            </mat-form-field>
          </div>
          <span class="link-forgot-password" (click)="forgotPassword()"
            >Esqueceu a senha?</span
          >
          <div class="mt-4">
            <button
              *ngIf="!isForgotPassword"
              class="btn btn-outline-success"
              (click)="onSubmit(formLogin.value)">
              Entrar
            </button>
            <button
              class="btn btn-outline-success"
              *ngIf="isForgotPassword"
              (click)="submitRecoverPassword(formLogin.value)">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
