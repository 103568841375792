import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NbLogoutComponent, NbRegisterComponent } from '@nebular/auth';
import { LoginComponent } from './pages/login/login.component';
import { UpdatePasswordComponent } from './pages/login/update-password/update-password.component';
import { AuthComponent } from './components/auth/auth.component';
import { authGuard } from './guards/auth.guard';
import { localhostGuard } from './guards/localhost.guard';
import { NotFoundComponent } from './pages/not-found/not-found.component';

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [authGuard],
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'update-password/:id',
        component: UpdatePasswordComponent,
      },
    ],
  },
  {
    path: 'component-docs',
    loadChildren: () =>
      import(
        './components/documentation/component-documentation-routing.module'
      ).then(m => m.ComponentDocumentationRoutingModule),
    canActivate: [localhostGuard],
  },
  {
    path:'not-found',
    component: NotFoundComponent
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
