export function sanitizeObject<T>(rawObject: T): Partial<T> {
    if (!rawObject) return {};
  
    const sanitizedObject: Partial<T> = {};
    Object.keys(rawObject).forEach(key => {
      /** Bypass clientId */
      if (key === 'clientId') {
        sanitizedObject[key as keyof T] = rawObject[key as keyof T];
        return;
      }
  
      const value = rawObject[key as keyof T];
  
      // Verifica se o valor é um objeto e não é nulo
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        // Chama recursivamente a função para sanitizar objetos aninhados
        sanitizedObject[key as keyof T] = sanitizeObject(value as never) as never;
      } else if (value !== null && value !== undefined && value !== '') {
        // Atribui o valor se for válido (não nulo, não indefinido e não vazio)
        sanitizedObject[key as keyof T] = value;
      }
    });
  
    return sanitizedObject;
  }