import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

export const localhostGuard: CanActivateFn = async () => {
  const router = inject(Router);

  const hostname = window.location.hostname;

  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return true;
  } else {
    await router.navigateByUrl('/pages');
    return false;
  }
};
